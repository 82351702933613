const technologies = [
  {
    img: "https://pbs.twimg.com/profile_images/1654266646809255936/7qaS6N5-_400x400.jpg",
    url: "https://bluntdao.org/",
    title: "OG Validator",
    company: "OG Validator",
  },
  {
    img: "https://pbs.twimg.com/profile_images/1654266646809255936/7qaS6N5-_400x400.jpg",
    url: "https://x.com/plugrel",
    title: "Super OG Validator",
    company: "BluntDAO",
  },



];

export default technologies;
